
import React from 'react'
import { Button, ButtonGroup, CardActions, Chip, Grid, makeStyles } from "@material-ui/core/";

const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(0),
      [theme.breakpoints.down("sm")]: {
        minWidth: 32,
        paddingLeft: 8,
        paddingRight: 8,
        "& .MuiButton-startIcon": {
          margin: 0
        }
      }
    },
    buttonText: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    }
  }));

export default function TaleSort({sortData,sortName}) {

    
    const classes = useStyles();

    return (
        <ButtonGroup  
        // orientation={`${matches ? `horizontal` : `vertical`}`}
  >
          <Button 
          startIcon={<span className="material-icons">thumb_up</span>}
           className={classes.button} 
           variant={sortName === "like" ? "contained" : "outlined"} 
           color={sortName === "like" ? "secondary" : "default"} 
           onClick={()=>sortData("like")}> 
          <span 
          className={classes.buttonText}          
          >Beğeni Sayısına Göre
          </span> 
           </Button>
 
          <Button 
                   startIcon={<span className="material-icons">visibility</span>}
                   className={classes.button} 
          variant={sortName === "viewCount" ? "contained" : "outlined"} 
          color={sortName === "viewCount" ? "secondary" : "default"}  
          onClick={()=>sortData("viewCount")}
          >
                 <span 
          className={classes.buttonText}          
          >Görüntülenme Sayısına Göre
          </span> 
        </Button>
          <Button 
                   startIcon={<span className="material-icons">event_note</span>}
                   className={classes.button} 
          variant={sortName === "date" ? "contained" : "outlined"} 
          color={sortName === "date" ? "secondary" : "default"}  
          onClick={()=>sortData("date")}>
                 <span 
          className={classes.buttonText}          
          >En Yeniler
          </span> 
          </Button>
          <Button 
                   startIcon={<span className="material-icons">sort_by_alpha</span>}
                   className={classes.button} 
          variant={sortName === "alphabetical" ? "contained" : "outlined"} 
          color={sortName === "alphabetical" ? "secondary" : "default"} 
           onClick={()=>sortData("alphabetical")}>
                  <span 
          className={classes.buttonText}          
          >Alfabetik
          </span> 
               </Button>
        </ButtonGroup>
    )
}
