import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import config from '../config';
//import config from '../../../config';

class SelectCityDialog extends Component {
  handleRequestClose = () => {
    this.props.onClose(this.props.selectedValue);
    
  };

  

  state= {
    talesByCityData : [],
  }


  handleListItemClick = (item)=>{
    const body = document.body;
    body.style.height = '100vh';
    body.style.overflowY = 'hidden';

       this.props.handleListItemClick(item);

  }
  render() {
    const {classes,  onClose,title, selectedValue,addItemStatus, ...other} = this.props;
    let talesByCityData = this.props.talesbycitydata;
   // talesByCityData !== undefined ?   true : talesByCityData = [];
    
    
      return (
      <Dialog onClose={this.handleRequestClose} {...other}>
        <DialogTitle>{title} Masal Listesi</DialogTitle>
        <div>
          <List>
            {talesByCityData.map(item =>
              <ListItem button onClick={()=>this.handleListItemClick(item)} key={item.taleId}>
                <ListItemAvatar>
                  <Avatar alt={item.name +' '+item.surname} src={config.apiUrl +  item.profileImageUrl}/>
                </ListItemAvatar>
                <ListItemText primary={item.taleName}/>
              </ListItem>,
            )}
          </List>
        </div>

      </Dialog>
    );
  }
}
export default SelectCityDialog;
