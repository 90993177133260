let envConfig = {};
const devConfig = {
 //apiUrl: "http://192.168.0.12:3300",
  //apiUrl: "http://192.168.55.51:3300",
  apiUrl: "http://localhost:3300",

  pageUrl: "http://localhost:3005/",
  pageUrl: "http://masal.gov.tr/",

};
const testConfig = {
  apiUrl: "https://test.masal.gov.tr/api/",
  pageUrl: "https://test.masal.gov.tr/",

};
const prodConfig = {
  apiUrl: "https://masal.gov.tr/api/",
  pageUrl: "https://masal.gov.tr/",

};
if (process.env.REACT_APP_ENV === "development") {
  envConfig = devConfig;
} else if (process.env.REACT_APP_ENV === "test") {
  envConfig = testConfig;
} else if (process.env.REACT_APP_ENV === "production") {
  envConfig = prodConfig;
}

export default {
    pageUrl : envConfig.pageUrl,   
    apiUrl: envConfig.apiUrl,

}