import {
  Card,
  CardContent, Typography
} from "@material-ui/core";
  

export default function SearchTaleItemCardEmpty() {

      return (
        <Card className="m-1 w-100" variant="outlined" style={{backgroundColor:'#dff8fe40'}}>
        <CardContent>
  
          <Typography variant="h5" component="h2">
                Arama sonuçlarına uygun masal bulunamadı.
          </Typography>
       
        </CardContent>
      
  
      </Card>
  
      );
    
  
  }
  