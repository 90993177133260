
import {
  Skeleton
} from "@material-ui/lab";


import React, { useEffect, useState } from 'react';
import axios from 'axios'
import SimpleDialog from "./Component/SimpleDialog";
import AppTaleDialog from "./Component/AppTaleDialog";
import config from "./config";
import { getTaleById, getTaleRandom, getAppTaleById } from "./api";



export default class MainApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: false,
      open: false,
      appData: [],
      appOpen: false,
    };
  }


  componentDidMount = async () => {
    this.setState({ loading: true })
    // //console.log("result " , result)

    const taleId = new URL(window.location.href).searchParams.get('t');
    const appTaleId = new URL(window.location.href).searchParams.get('at');

    //console.log("this.props.location.searc ",window.location.href,taleId )

    if (appTaleId !== null) {
      const res2 = await getAppTaleById(appTaleId);
      const result = await getTaleRandom();
      if (res2.result) {
        this.setState({
          appData: res2.data,
          appOpen: true,
          loading: false
        })
        this.setState({ data: result, loading: false })
      } else {
        this.setState({ data: result, loading: false })
      }
    } else {
      if (taleId !== null) {
        const res1 = await getTaleById(taleId);
        this.setState({
          data: res1[0],
          open: true,
          loading: false
        })
      }
      else {
        const result = await getTaleRandom();
        this.setState({ data: result, loading: false })

      }
    }
  }

  render() {
    const { data, open, loading, appData, appOpen } = this.state;


    return (
      <div className="container">
        <div className="row space-100">
          <div className="col-lg-8 col-md-12 col-xs-12">
            <div className="contents">
              <h2 className="head-title" >Günün Masalı</h2>
              <h3 className="head-title" style={{ fontWeight: 'bold', color: '#7fc9fb' }}>{loading ? <Skeleton variant="text" width={250} /> : data.taleName}</h3>


              {loading ? [<Skeleton key="abc1" variant="text" width={600} />, <Skeleton key="abc2" variant="text" width={400} />] : <div className="text-justify" style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: String(String(data.taleText).replace(/&nbsp;/g, '')).replace(/<[^>]*>?/gm, '').substring(0, 200) + "..." }} />
              }


              <div className="header-button">
                {
                  loading ? <Skeleton variant="rect" width={200} height={50} className="mt-4" /> : <a className="btn btn-border-filled"
                    onClick={() => {
                      this.setState({ open: true })
                      const body = document.body;
                      body.style.height = '100vh';
                      body.style.overflowY = 'hidden';
                      body.style.paddingRight = 0;
                    }} >
                    Devamını Oku
                  </a>
                }

                {/* <a href="/masal" rel="nofollow" target="_blank" className="btn btn-border-filled">Devamını Oku</a> */}
              </div>

              <SimpleDialog selectedValue={data} open={open} onClose={() => {
                const body = document.body;
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                body.style.height = '';
                body.style.overflowY = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);

                this.setState({ open: false })
              }} />

              <AppTaleDialog
                selectedValue={appData}
                open={appOpen}
                coverPath={appData.files?.filter(data => data.type == 1)[0].path}
                cover2Path={appData.files?.filter(data => data.type == 2)[0].path}
                soundPath={appData.files?.filter(data => data.type == 5)[0].path}
                onClose={() => {
                  const body = document.body;
                  const scrollY = body.style.top;
                  body.style.position = '';
                  body.style.top = '';
                  body.style.height = '';
                  body.style.overflowY = '';
                  window.scrollTo(0, parseInt(scrollY || '0') * -1);

                  this.setState({ appOpen: false })
                }} />
            </div>

          </div>
          <div className="col-lg-4 col-md-12 col-xs-12 p-0">
            <div className="intro-img w-100">
              <img src="img/tumak-logo/buyuk-boy-logo/buyuk2.png" alt="" />
            </div>
          </div>

        </div>

      </div>)
  }

}


// const domContainer = document.querySelector('#reactContainer-TaleOfTheDay');
// ReactDOM.render(<ReactContainer />, domContainer);

