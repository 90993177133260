
//const apiUrl = "http://localhost:3300";

import   BubbleMap from "./Component/BubbleMap";
import axios from 'axios';
import React from 'react';
import config from "./config";
import { getStatisticsCities } from "./api";
import { Skeleton } from "@material-ui/lab";

class MapTale extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      data: [] ,
      loading:false
    };
  }
 





  componentDidMount =  async()=>{
    this.setState({
      loading:true
    })
 
    const result = await getStatisticsCities(); 

    //console.log("result *********** " , result  )

    this.setState({
      data:result,
      loading:false,
    
    }) 
}


  render()
  {
    const {data, loading} = this.state;


    return (
  <div className="container">
       <div className="row">
        <div className="col-lg-12">
          <div className="features-text text-center">
            <div>
              <h2 className="section-title">Masal Haritası</h2>            
            </div>
          </div>
        </div>

      </div>

  <div className="row space-100">
    <div className="col-md-12">
      {loading ?   <Skeleton  variant="rect" width={200}  height={50} className="mt-4" /> :
    data.length > 0 ?  <BubbleMap citiesStatisticsData={data}/> : null
    }

</div>
  
  </div>



 </div>)
}

}
export default MapTale;


