
 export const citys = ['','Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 
'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 
'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce']

export const areas = [
 
    {
      id:1,
      value: "Doğu Anadolu Bölgesi",
      label: "Doğu Anadolu Bölgesi"
      ,color :"warning"
    },
    {
      id:2,
      value: "İç Anadolu Bölgesi",
      label: "İç Anadolu Bölgesi"
      ,color :"primary"
    },
    {
      id:3,
      value: "Marmara Bölgesi",
      label: "Marmara Bölgesi"
      ,color :"success"
    },
    {
      id:4,
      value: "Güneydoğu Anadolu Bölgesi",
      label: "Güneydoğu Anadolu Bölgesi"
      ,color :"info"
    },
    {
      id:5,
      value: "Akdeniz Bölgesi",
      label: "Akdeniz Bölgesi"
      ,color :"danger"
    },
    {
      id:6,
      value: "Karadeniz Bölgesi",
      label: "Karadeniz Bölgesi"
      ,color :"secondary"
    },
    {
      id:7,
      value: "Ege Bölgesi",
      label: "Ege Bölgesi"
      ,color :"teal"
    }
  ]

export const taleTypes = [
  "Zincirlemeli Masallar","Sihirli/Olağanüstü Masallar","Mizahi Masallar","Hayvan Masalları","Gerçekçi Masallar","Dini Masallar","Diğer Masallar"
]