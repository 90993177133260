import axios from 'axios';
import config from '../config';




export const getTaleRandom = () => {
  const url = `${config.apiUrl}/v1/public/tales/random`;
  return axios.get(url)
    .then(response => response.data);
}
export const getTaleById = (id) => {
  const url = `${config.apiUrl}/v1/public/tales/${id}`;
  return axios.get(url)
    .then(response => response.data);
}
export const postTalesByPropertyApi = (property) => {
  const url = `${config.apiUrl}/v1/public/tales/byProperty`;
  return axios.post(url, property)
    .then(response => response.data);
}


export const getStatisticsCities = () => {
  const url = `${config.apiUrl}/v1/public/tales/statistics/cities`;
  return axios.get(url)
    .then(response => response.data);
}

export const getAppTaleById = (id) => {
  const url = `${config.apiUrl}/v1/public/tales/app/tale/${id}`;
  return axios.get(url)
    .then(response => response.data);
} 
