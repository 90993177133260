
//const apiUrl = "http://localhost:3300";

import {
  Box, Button, Checkbox,  Chip, FormControl, FormControlLabel,  FormGroup, FormLabel,  Input, InputLabel,  ListItemText, makeStyles,  MenuItem,  Select,  TextField
} from "@material-ui/core";
import {
  Pagination
} from "@material-ui/lab";
import axios from 'axios';
import React from 'react';
import LoadingTaleItemCard from "./Component/LoadingTaleItemCard";
import SearchTaleItemCard from "./Component/SearchTaleItemCard";
import SearchTaleItemCardEmpty from "./Component/SearchTaleItemCardEmpty";
import SimpleDialog from "./Component/SimpleDialog";
import config from "./config";
import { areas, citys, taleTypes } from "./util";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TaleSort from "./Component/TaleSort";



 

 function SearchBox(props) {

  const { placeholder ,onChange , value} = props;

    return (
      <Box p={2} className="w-100">
 
      <TextField
      fullWidth
      variant="outlined"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        InputProps={{
          startAdornment: <span className="material-icons">
          search
          </span>,
        }}
      />
    </Box>
    );
  

}



class SearchTale extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = { 
      data: [] ,
      data2:[],
      open : false,

      city :"",
      area:"",
      selectedCity:[],
      selectedArea:[],
      selectedTaleType:[],
      selectedCompiler:"",
      compilers:[],
      searchText:"",
      loading:true,

      currentPage:1,
      postsPerPage:5,

      selectedTale : {
        taleId:0
      },

      sortName:""
    };
  }

  filterData = (filterName,value)=>{
    let data = this.state.data2;

    //console.log("filterName " , filterName , " -> " , value , this.state.searchText)
    if(filterName==="searchText"){
      if(value.length>0)
      {
        data = data.filter(item=>  
          ( 
            (item.compilerNameSurname.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
            (item.taleName.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
            (item.taleText.toLocaleLowerCase().includes(value.toLocaleLowerCase()))    
          )
          
        ) ;
      }
      else
      {
        data = this.state.data2;
      }
    }
    else if (filterName==="selectedArea")
    {
      if(value.length>0)
      {
        data = data.filter(item=> value.some(x=>x===item.area)  ) ;
      }
     
    }
    else if (filterName==="selectedCity")
    {
      if(value.length>0)
      {
        data = data.filter(item=> value.some(x=>x===item.city)  ) ;
      }
     
    }
    else if (filterName==="selectedTaleType")
    {
      if(value.length>0)
      {
        data = data.filter(item=> value.some(x=>x===item.groupName)  ) ;
      }
     
    }   
    else if (filterName==="selectedCompiler")
    {
      if(value.length>0)
      {
        data = data.filter(item=> value === item.compilerNameSurname ) ;
      }
     
    }
    else if (filterName==="selectedLike_0_10" && value )
    {
        data = data.filter(item=> item.likes < 11 ) ;
    }
    else if (filterName==="selectedLike_10_100" && value )
    {
        data = data.filter(item=> item.likes> 9 && item.likes < 101 ) ;
    }
    else if (filterName==="selectedLike_1000_10000" && value )
    {
        data = data.filter(item=> item.likes > 101 ) ;
    }

    
    ////buradan sonrası önceki seçilmiş filtereleri dahil et

    if(filterName !== "searchText" && this.state.searchText.length>0)
    {
      data = data.filter(item=>  
        ( 
          (item.compilerNameSurname.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())) ||
          (item.taleName.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())) ||
          (item.taleText.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))    
        )
        
      ) ;
    }
    if(filterName !== "selectedArea" && this.state.selectedArea.length>0)
    {
      data = data.filter(item=> this.state.selectedArea.some(x=>x===item.area)  ) ;
    }
    if(filterName !== "selectedCity" && this.state.selectedCity.length>0)
    {
      data = data.filter(item=> this.state.selectedCity.some(x=>x===item.city)  ) ;
    }
    

    if(filterName !== "selectedTaleType" && this.state.selectedTaleType.length>0)
    {
      data = data.filter(item=> this.state.selectedTaleType.some(x=>x===item.groupName)  ) ;
    }
    if(filterName !== "selectedCompiler" && this.state.selectedCompiler.length>0)
    {
      data = data.filter(item=> this.state.selectedCompiler === item.compilerNameSurname ) ;
    }
    if (filterName !=="selectedLike_0_10" && this.state.selectedLike_0_10 )
    {
        data = data.filter(item=> item.likes < 11 ) ;
    }
    if (filterName!=="selectedLike_10_100" && this.state.selectedLike_10_100 )
    {
        data = data.filter(item=> item.likes> 9 && item.likes < 101 ) ;
    }
    if (filterName!=="selectedLike_1000_10000" && this.state.selectedLike_1000_10000 )
    {
        data = data.filter(item=> item.likes > 101 ) ;
    }






    
    this.setState({
      data
    })
  }

  getTaleAll = ()=>{
    const url = `${config.apiUrl}/v1/public/tales/`;
    return axios.get(url)
    .then(response => response.data);
 } 
 getTCompilerAll = ()=>{
  const url = `${config.apiUrl}/v1/public/tales/compilers/`;
  return axios.get(url)
  .then(response => response.data);
} 
  componentDidMount =  async()=>{
    this.setState({
      loading:true
    })
 
    const result = await this.getTaleAll(); 

   // const result2 = await this.getTCompilerAll();
     
    //console.log("result " , result , result2 )
    this.setState({
      data:result.filter(x=>x.visible===1),
      data2:result.filter(x=>x.visible===1),
      loading:false,
    //  compilers:result2.map(x=>x.compilerNameSurname)
    
    }) 
}
handleChangeCategory = (value, name)=>{
  let cat = this.state.category;
  cat[name]=value;
  this.setState({
    category : cat
  })
  //console.log("çalıştı ", value, name , cat)
}
handleChange = name => (event) => {
 // setPersonName(event.target.value);
  this.setState({
    [name] : event.target.value
  })

  this.filterData(name, event.target.value)
};

handleChangeCheckbox = name => (event) => {
  // setPersonName(event.target.value);
   this.setState({
     [name] : event.target.checked
   })
 
   this.filterData(name, event.target.checked)
 };

 onChangePagination = (event,value)=>{
 // //console.log("valuer ",event,value)
  this.setState({
    currentPage:value
  })

  window.location.href = "#reactContainer-searchTale"
 }

 clearFilter = ()=>{
   this.setState({
    selectedCity:[],
    selectedArea:[],
    selectedTaleType:[],
    selectedCompiler:"",
    searchText:"",
    data: this.state.data2,
    sortName:""
   })
 }

 selectedItem =(item)=>{

      
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  const body = document.body;
  body.style.height = '100vh';
  body.style.overflowY = 'hidden';

   this.setState({
     selectedTale : item,
     open:true
   })
 } 

 sortData = (sortName)=>{
   let {data} = this.state;
    let newSortName = sortName;

   if(sortName==="viewCount")
   {
     if(sortName !== this.state.sortName)
     {
        data = data.sort((x,y)=>{
          return y.viewCount - x.viewCount
        })
     }
     else
     {
       data = this.state.data2;
      newSortName="";
     }
  
   }
   if(sortName==="like")
   {
    if(sortName !== this.state.sortName)
    {
      data = data.sort((x,y)=>{
        return y.likes - x.likes
      })
    }
    else
    {
      data = this.state.data2;
     newSortName="";
    }
  
   }
   if(sortName==="date")
   {
    if(sortName !== this.state.sortName)
    {
      data = data.sort((x,y)=>{
        return new Date(y.createDate) - new Date(x.createDate)
      })
    }
    else
    {
      data = this.state.data2;
     newSortName="";
    }
  
   }
   if(sortName==="alphabetical")
   {
    if(sortName !== this.state.sortName)
    {
      data = data.sort((a, b)=> {
          // Use toUpperCase() to ignore character casing
          const bandA = a.taleName.toUpperCase();
          const bandB = b.taleName.toUpperCase();
        
          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }
      )
    }
    else
    {
      data = this.state.data2;
     newSortName="";
    }
  
   }
   this.setState({
     sortName:newSortName,
     data 
   })
 }
  render()
  {
    const {data, open,loading,currentPage,postsPerPage} = this.state;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentData = this.state.data.slice(indexOfFirstPost,indexOfLastPost);
  const pageNumbers = Math.ceil(data.length / postsPerPage);
  

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));
//const classes = useStyles();
//const matches = useMediaQuery("(min-width:600px)");


    return (
  <div className="container">
       <div className="row">
        <div className="col-lg-12">
          <div className="features-text text-center">
            <div>
              <h2 className="section-title">Masal Arama</h2>            
            </div>
          </div>
        </div>

      </div>

  <div className="row space-100">
    <div className="col-md-3 p-2 card " style={{backgroundColor:'#29cdf626'}}>
      <h6>
        Filtreler
      </h6>

    <FormControl fullWidth className="my-3">
        <InputLabel id="demo-mutiple-checkbox-label">Bölge</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={this.state.selectedArea}
          onChange={this.handleChange("selectedArea")}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {areas.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={this.state.selectedArea.indexOf(item.value) > -1} />
              <ListItemText primary={item.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    <FormControl  fullWidth className="my-3">
        <InputLabel id="demo-mutiple-chip-label">Şehir</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={this.state.selectedCity}
          onChange={this.handleChange("selectedCity")}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {citys.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth className="my-3">
        <InputLabel id="demo-mutiple-checkbox-label">Masal Türleri</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={this.state.selectedTaleType}
          onChange={this.handleChange("selectedTaleType")}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {taleTypes.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={this.state.selectedTaleType.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <FormControl fullWidth className="my-3">
        <InputLabel id="demo-mutiple-checkbox-label3">Derleyen</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label3"
          id="demo-mutiple-checkbox3"          
          value={this.state.selectedCompiler}
          onChange={this.handleChange("selectedCompiler")}
          input={<Input />}
    //      renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
           <MenuItem  value={""}>    
           <ListItemText primary={"Tümü"} />     
            </MenuItem>

          {this.state.compilers.map((item) => (
            <MenuItem key={item} value={item}>         
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

 <FormControl component="fieldset" fullWidth className="my-3" >
        <FormLabel component="legend">Beğeni Sayısı</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedLike_0_10} 
            onChange={this.handleChangeCheckbox("selectedLike_0_10")} 
            name="0-10 Beğeni" />}
            label="0-10 Beğeni"
          />
           <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedLike_10_100} 
            onChange={this.handleChangeCheckbox("selectedLike_10_100")} 
            name="10-100 Beğeni" />}
            label="10-100 Beğeni"
          />
           <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedLike_100_99999} 
            onChange={this.handleChangeCheckbox("selectedLike_100_99999")} 
            name="100-10000 Beğeni" />}
            label="100-10000 Beğeni"
          />
         
        </FormGroup>
      </FormControl>
      
      <Button color="secondary" variant="contained" onClick={this.clearFilter}>
        Filtre Temizle
      </Button>

      {/* <FormControl component="fieldset" fullWidth className="my-3" >
        <FormLabel component="legend">Görüntülenme Sayısı</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedViewCount} 
            onChange={this.handleChange("0-10")} 
            name="0-10 Beğeni" />}
            label="0-10 Beğeni"
          />
           <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedViewCount} 
            onChange={this.handleChange("10-100")} 
            name="10-100 Beğeni" />}
            label="10-100 Beğeni"
          />
           <FormControlLabel
            control={<Checkbox 
            checked={this.state.selectedViewCount} 
            onChange={this.handleChange("100-10000")} 
            name="100-10000 Beğeni" />}
            label="100-10000 Beğeni"
          />
         
        </FormGroup>
      </FormControl> */}
   
    </div>
    <div className="col-md-9">
      <div className="row">
       
          <SearchBox placeholder="Arama Yap"  value={this.state.searchText}  onChange={this.handleChange("searchText")}/>
      </div>
     <div className="row justify-content-center mb-2">
     {/* <h6>Sıralama Yap</h6> */}
    <TaleSort sortData={this.sortData} sortName={this.state.sortName}/>
     </div>
     
     {/* ghost loader eklenecek */}
     <div className="row">
   {loading &&  <LoadingTaleItemCard />}
    {loading && <LoadingTaleItemCard />}
     
     {
       currentData.map(x=>{
        //kart tasarımı yapılacak en alttta beğen - paylaş - vs olacak :)
        
        //return <CryptoNewsItem tale={x} />
       
        return <SearchTaleItemCard key={x.taleId} tale={x} selectedItem={this.selectedItem} />
       })
     }
     {
       (currentData.length === 0 &&  !loading) &&
       <SearchTaleItemCardEmpty />
     }
     </div>
  
     <div className="row justify-content-between py-2">
          <div className="p-2">
          Görüntülenen Masal : 
<FormControl variant="standard" size="small" className="ml-1">
        <Select
          id="demo-simple-select-outlined"
          value={this.state.postsPerPage}
          onChange={this.handleChange("postsPerPage")}
          label="Görüntülenen Masal"
          
        >
       
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>

        </Select>
      </FormControl>

          </div>


    <Pagination count={pageNumbers} page={currentPage} variant="outlined" color="secondary" disabled={data.length<3} onChange={this.onChangePagination}/> 

<div className="p-2">

  Toplam Masal : {data.length} 
</div>
     </div>

    </div>
  
  </div>


  <SimpleDialog selectedValue={this.state.selectedTale}  open={open} onClose={()=>{
          const body = document.body;
          const scrollY = body.style.top;
          body.style.position = '';
          body.style.top = '';
          body.style.height = '';
          body.style.overflowY = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
    this.setState({open:false})}} />

 </div>)
}

}
export default SearchTale;

// const domContainer = document.querySelector('#reactContainer-searchTale');
// ReactDOM.render(<ReactContainer />, domContainer);

