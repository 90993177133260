import {
    FormLabel,
    CssBaseline,
    ThemeProvider,
    Typography,
    Container,
    makeStyles,
    createMuiTheme,
    DialogContent,
    FormControlLabel,
    Button,
    Dialog,DialogTitle,
    MenuItem ,
    InputLabel,
    Select,
    FormControl,
    FormGroup,
    Checkbox,
    TextField,
    InputBase,
    Box ,
    Chip,
    Input,
    ListItemText ,
    Card ,
    CardContent ,
   CardActions,
  IconButton,
  ShareIcon,
  MoreVertIcon,
  FavoriteIcon ,
  Avatar,
  Divider,
  VisibilityIcon,
  ThumbUpIcon,
  ThumbDownIcon
  } from "@material-ui/core";
import TaleAction from "./TaleAction";
  

export default function SearchTaleItemCard(props) {

    const { tale , selectedItem } = props;
  
  
      return (
        <Card className="m-1 w-100" variant="outlined" style={{backgroundColor:'#dff8fe40'}}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {tale.area} / {tale.city}
          </Typography>
          <Typography variant="h5" component="h2">
          {String(tale.taleName).replace("[","").replace("]","")}
          </Typography> 
          <Typography color="textSecondary">
            {tale.groupName}
          </Typography>
          <Typography variant="body2" component="p">
            <span dangerouslySetInnerHTML={{__html:String(String(tale.taleTextEnd).replace(/&nbsp;/g,'')).replace(/<[^>]*>?/gm, '').substring(0,170) + "..."}}></span>
       
            <Button className="secondary ml-1" size="small" onClick={()=>selectedItem(tale)}>
                       Devamını Oku
                     </Button>
          </Typography> 
  
          {/* <Chip  size="small"
          avatar={<Avatar alt={tale.compilerNameSurname} src="/static/images/avatar/1.jpg" />}
          label={tale.compilerNameSurname}
          variant="outlined"
          className="mr-2"
        /> */}
        </CardContent>
        <Divider light />
  
   <TaleAction  tale={tale}/>
  
      </Card>
  
      );
    
  
  }
  