import React, {Component} from "react";
import {ComposableMap, Geographies, Geography, Marker, Markers, ZoomableGroup,Annotation,Annotations} from "react-simple-maps";
import {scaleLinear} from "d3-scale";
//import cities from "./static/world-most-populous-cities.json";
//import geography from "./static/world-50m.json";
//import cities from './static/turkey-cities2.json';
import geography from "./static/turkey-50m.json";
import { postTalesByPropertyApi, getTaleFullByTaleIdApi, getTaleById } from "../../api/index";
import SelectCityDialog from "../SelectCityDialog";
import SimpleDialog from "../SimpleDialog";
//import { Button } from "@material-ui/core";



const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto",
  height:300,
}

const cityScale = scaleLinear()
  .domain([0, 37843000])
  .range([1, 25])

class BubbleMap extends Component {

  state = {
    open: false, 
    selectedValue: {},
    talesByCityData : [],
    city:"",
    isAssistantEditor:false,
    previewTaleModalState:false,
    selectedTale : {
      uploadList:[],
      sourcePerson : {
        job :'',
        nameSurname :'',
        learnedFrom:'',
        educationLevel : '',
        placeOfBirth : '',
        birthDate:'',
        complationDate :new Date()
      },
    } 
  }

 

  handleClick = async(marker, evt)=> {


    const property = {city : marker.city};
    const talesByCityData = await postTalesByPropertyApi(property);
    const city =  marker.city;
    this.setState({talesByCityData,open:true,city,selectedValue:marker});

    const body = document.body;
    //  body.style.height = '100vh';
      body.style.overflowY = 'hidden';
      body.style.paddingRight=0;
  }

  
  handleRequestClose = () => {
    this.setState({    talesByCityData : [], open: false});
  };
  
  handleListItemClick = async value => {

//console.log("seçilen ",value)
    
    const tale = await getTaleById(value.taleId);
    tale.uploadList = [];
    //console.log("seçilen tale ",tale[0])

    this.setState({
      previewTaleModalState: true,
      selectedTale:tale[0],
      open:false
    });

   // this.props.onClose(value);
   const body = document.body;
   body.style.overflowY = 'hidden';
   body.style.paddingRight=0;
  };

  render() {
    const citiesStatisticsData = this.props.citiesStatisticsData;
 //   //console.log("citiesStatisticsData",citiesStatisticsData)
    return (
      <div style={wrapperStyles}>
        <ComposableMap
          projectionConfig={{
            scale: 3400,
            xOffset: 0,
            yOffset: 0,
            rotation: [0,0,0],
         //   precision: 0.1,
          
          }}
          width={980}
          height={450}
          style={{
            width: "100%",
            height: "auto",
          }}
        >
       
       <ZoomableGroup center={[ 35, 39 ]} disablePanning>
          <Geographies geography={geography}>
            {(geographies, projection) => geographies.map(geography => (
              <Geography
                key={geography.id }
                geography={ geography }
                projection={ projection }
                style={{
                  default: {
                    fill: "#ECEFF1",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                  hover: {
                    fill: "#ECEFF1",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                  pressed: {
                    fill: "#ECEFF1",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                }}
              //  onClick={this.handleClick}
                />
            ))}
          </Geographies>


<Annotations>
  {
    citiesStatisticsData.map((annotation, i) => (
      <Annotation
        key={i}
        dx={ 0 }
        dy={ 0 }
        subject={ annotation.coordinates }
        strokeWidth={1}
      
        >
        <text style={{fontSize:"18px",fontWeight:"bold",lineHeight:"18px",cursor:"pointer"}}   >
          { annotation.total }
        </text>
      </Annotation>
    ))
  }
</Annotations> 

<Markers>
      {
                citiesStatisticsData.map((city, i) => (
                  
                  <Marker key={i} marker={city}   onClick={ this.handleClick }>
                    <circle
                      cx={0}
                      cy={-4}
                      r={cityScale( 22000000)}
                     
                      fill="rgba(255,87,34,0.6)"
                      stroke="#607D8B"
                      strokeWidth="2"
                      style={{cursor:"pointer"}}
                    />
                
                  </Marker>
                ))
              } 
</Markers> 
          </ZoomableGroup>


     
        </ComposableMap>

        <SelectCityDialog talesbycitydata={this.state.talesByCityData}
                      selectedValue={this.state.selectedValue}
                      open={this.state.open}
                      title={this.state.city}
                      onClose={this.handleRequestClose.bind(this)}
  handleListItemClick =  {this.handleListItemClick}
  addItemStatus={false} 
                      />

      <SimpleDialog selectedValue={this.state.selectedTale} open={this.state.previewTaleModalState} onClose={()=>{
          const body = document.body;
          const scrollY = body.style.top;
          body.style.position = '';
          body.style.top = '';
          body.style.height = '';
          body.style.overflowY = '';
          window.scrollTo(0, parseInt(scrollY || '0') * -1);
        
        this.setState({previewTaleModalState:false})}} />

{/* <PreviewTaleModal onModalClose={this.handleChangePreviewTaleModalState} open={this.state.previewTaleModalState} tale={this.state.selectedTale}></PreviewTaleModal> */}
      </div>
    )
  }
}

export default BubbleMap
