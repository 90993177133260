import {
  Card,
  CardContent, Divider, Typography
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import TaleActionLoading from "./TaleActionLoading";


export default function LoadingTaleItemCard(props) {

    const { tale } = props;
  
  
      return (
        <Card className="m-1 w-100" variant="outlined" style={{backgroundColor:'#dff8fe40'}}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
          <Skeleton variant="rect" width={260} height={10} />
          </Typography>
          <Typography variant="h5" component="h2">
          <Skeleton variant="rect" width={220} height={20} />
          </Typography>
          <Typography color="textSecondary">
          <Skeleton variant="rect" width={210} height={10} className="my-1" />
          </Typography>
          <Typography variant="body2" component="p" className="my-1">
          <Skeleton variant="rect" width={700} height={50} />
       
          </Typography>
  
          <Skeleton variant="rect" width={130} height={25} />
        </CardContent>
        <Divider light />
   
   <TaleActionLoading  />
  
      </Card>
  
      );
    
  
  }
  