
import React,{useEffect,useState} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Dialog,
    DialogActions, Chip,
    Grid , CardActions
  } from "@material-ui/core/";
  
  export default function TaleActionLoading() {

    
    return (
      
      <CardActions className="justify-content-between">
  
  
      <div className="">
      <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="center"
>
       <Skeleton  variant="rect" width={140}  height={25} className="m-2 d-flex"/>    
       <Skeleton variant="rect" width={120} height={25} className="m-2 d-flex"/>    
       <Skeleton variant="rect" width={130} height={25} className="m-2 d-flex"/>   
</Grid>
      </div>
  
  
  <div>
  <Grid
  container
  direction="row"
  justify="flex-end"
  alignItems="center"
>
  <Skeleton variant="circle" width={35}  height={35} className="m-1 d-flex"/>
  <Skeleton variant="circle" width={35}  height={35} className="m-1 d-flex"/>
  <Skeleton variant="circle" width={35}  height={35} className="m-1 d-flex"/>
  <Skeleton variant="circle" width={35}  height={35} className="m-1 d-flex"/>

  </Grid>
  </div>
  
  
      
                    
  
      </CardActions>
    )
  }