import React from 'react';
import ReactDOM from 'react-dom';
import MapTale from './MapTale';
import SearchTale from './SearchTale';

const rootEl = document.getElementById('reactContainer-TaleOfTheDay');
const rootE2 = document.getElementById('reactContainer-searchTale');
const rootE3 = document.getElementById('reactContainer-mapTale');

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  // ReactDOM.render(
  //   <MainApp/>,
  //   rootEl
  // );
  // ReactDOM.render(
  //   <SearchTale/>,
  //   rootE2
  // );
  // ReactDOM.render(
  //   <MapTale/>,
  //   rootE3
  // );
}; 

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    // render(
    //   <MainApp/>,
    //   rootEl
    // );
    // render(
    //   <SearchTale/>,
    //   rootE2
    // );
    // render(
    //   <MapTale/>,
    //   rootE3
    // )
  });
}

render();
