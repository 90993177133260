


import React, { useEffect, useState } from 'react';
import axios from 'axios'
import {
  Avatar,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core/";
import TaleAction from './TaleAction';
import config from '../config';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

export default function AppTaleDialog(props) {
  const { onClose, selectedValue, open, coverPath, cover2Path, soundPath } = props;

  console.log("selectedValue ",selectedValue)
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <span className="material-icons">close</span>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  // const updateViewCountApi = async (tale) => {

  //   const url = `${config.apiUrl}/v1/public/tales/updateView`;
  //   return axios.put(url, { taleId: tale.taleId, viewCount: tale.viewCount })
  //     .then(response => response.data);
  // }

  // useEffect(async () => {
  //   if (selectedValue.id !== undefined && open) {
  //     //console.log("Masal görüntülendi 1",selectedValue.taleName , selectedValue.taleId)
  //     await updateViewCountApi(selectedValue)
  //   }
  // }, [selectedValue, open])

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={"md"}>
      <DialogTitle onClose={handleClose}>
        {selectedValue.name}

        {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <span className="material-icons">close</span>
              </IconButton> */}

      </DialogTitle>


      <DialogContent dividers>
        {/* <div className="taleText" dangerouslySetInnerHTML={{ __html: selectedValue.text }} /> */}
        <div
          className="app-login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="justify-content-center animated slideInUpTiny animation-duration-3" style={{ height: '100%', padding: '25px', background: 'radial-gradient(circle, rgba(1,174,177,1) 0%, rgba(30,93,157,1) 100%)', borderRadius: 15 }}>

            <h5 style={{ color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>{selectedValue.name}</h5>
            <h6 style={{ color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Derleyen: {selectedValue.compiler}</h6>
            <div style={{ textAlign: 'center', margin: '30px 0' }}>
              <img src={config.apiUrl + coverPath} style={{ width: '100%', height: 'auto', maxWidth: '400px' }} />
            </div>
            <div style={{ textAlign: 'center', margin: '30px 0' }}>
              <audio src={config.apiUrl + soundPath} controls />
            </div>
            <div style={{ textAlign: 'center', margin: '30px 0' }}>
              <img src={config.apiUrl + cover2Path} style={{ width: '100%', height: 'auto', maxWidth: '600px' }} />
            </div>

            <div className="appTaleText" dangerouslySetInnerHTML={{ __html: selectedValue.text }} style={{ textAlign: 'left', color: '#fff' }}></div>
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <img src={"img/masal-masal-turkiye-logo.png"} style={{ width: '100%', maxWidth: '200px' }} />
            </div>
            <div style={{ textAlign: 'center', margin: '10px 0' }}>
              <a href="https://apps.apple.com/tr/app/masal-masal-t%C3%BCrkiye/id6448985993?l=tr">
                <img src={"img/app-store-icon.png"} style={{ width: '125px', margin: '10px' }} />
              </a>
              <a href="https://apps.apple.com/tr/app/masal-masal-t%C3%BCrkiye/id6448985993?l=tr">
                <img src={"img/google-play-icon.png"} style={{ width: '125px', margin: '10px' }} />
              </a>
            </div>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  );
}